<template>
  <vs-card :class="isMobile && mobile">
    <div v-if="isMobile" slot="header">
      <h3>Stock Received</h3>
      <span v-if="isMobile" class="text-base text-green-dark font-medium">{{ this.clinic && '' }}</span>
    </div>
    <form autocomplete="off" class="drugbookFields">
      <div class="flex flex-col space-y-4">

        <div class="w-full">
          <label class="text-md">Date added</label>
          <Datepicker
            format="dd MMMM yyyy"
            v-model="drugbookData.entryDate"
            :input-class="{
              'is-true':
                !errors.has('drugbookData.entryDate') && drugbookData.entryDate,
              'is-danger': errors.has('drugbookData.entryDate'),
            }"
          >
            <template slot="afterDateInput">
              <span
                v-if="drugbookData.entryDate"
                class="bg-color-done input-icon-validate vs-input--icon-validate"
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
              </span>
              <span
                v-if="errors.has('drugbookData.entryDate')"
                class="bg-color-error input-icon-validate vs-input--icon-validate"
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </template>
          </Datepicker>
        </div>

      <div class="w-full">
        <label class="text-md">Time added</label>
          <!-- label="Time Added" -->
          <!-- val-icon-success="done"
            val-icon-danger="error" -->
          <vs-input
            type="time"
            name="timeAdded"
            data-vv-as="Time Added"
            class="w-full"
            v-model="drugbookData.timeAdded"
          />
        </div>

      <div class="w-full">
        <label class="text-md">Select Drug</label>
          <!-- label="Select Drug" -->
          <vs-select
            :disabled="!isProductLoaded"
            ref="productSelect"
            class="select relative"
            width="100%"
            placeholder="Select Drug"
            :danger="errors.first('productId') ? true : false"
            :success="
              !errors.first('productId') && drugbookData.productId !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-as="productId"
            autocomplete
            name="productId"
            :danger-text="errors.first('productId')"
            v-model="drugbookData.productId"
          >
            <div :key="index" v-for="(item, index) in filteredProducts">
              <vs-select-group :title="item.title" v-if="item.group">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in item.group"
                />
              </vs-select-group>
              <!-- <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in filteredProducts"
            /> -->
            </div>
            <vs-button
              v-if="isProductLoaded"
              class="absolute w-full right-0 py-2 rounded-t-none rounded-b-sm justify-start pl-3 z-10"
              :class="isCustomProduct ? 'hidden' : 'customButton'"
              color="#e1e8e6"
              text-color="#475c55"
              icon="add"
              @click="addCustomProduct"
            >
              Add new Product
            </vs-button>
            <!-- @input-change="handleSuburbSearch($event.target.value)" -->
          </vs-select>
        </div>

      <div
        v-if="drugbookData.productId === 'add'"
        class="w-full"
      >
          <vs-input
            :danger="errors.first('customProductName') ? true : false"
            :danger-text="errors.first('customProductName')"
            :success="
              !errors.first('customProductName') && customProductName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-as="customProductName"
            name="customProductName"
            label="New Product Name"
            placeholder="New Product Name"
            v-model="customProductName"
            class="w-full"
          />
      </div>
      <div
        v-if="drugbookData.productId === 'add'"
        class="w-full"
      >
          <vs-input
            :danger="errors.first('customProductUnit') ? true : false"
            :danger-text="errors.first('customProductUnit')"
            :success="
              !errors.first('customProductUnit') && customProductUnit != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-as="customProductUnit"
            name="customProductUnit"
            label="Units of Measurement"
            placeholder="Units of Measurement"
            v-model="customProductUnit"
            class="w-full"
          />
        </div>

        <div class="w-full">
          <label for="amount" class="text-md"> {{ `Amount Received (${drugDetail?drugDetail.unit || '':''})` }} </label>
          <vs-input
            :danger="errors.first('drugbookData.amount') ? true : false"
            :danger-text="errors.first('drugbookData.amount')"
            :success="
              !errors.first('drugbookData.amount') && drugbookData.amount != ''
            "
            data-vv-scope="drugbookData"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{
              required: true,
              min_value: 0.1,
            }"
            data-vv-as="amount"
            name="amount"
            placeholder="Amount Received"
            v-model.number="formattedAmount"
            class="w-full"
          />
      </div>
      <div class="w-full">
          <vs-select
            :disabled="isNZRegion"
            autocomplete
            label="Source"
            class="select"
            width="100%"
            name="authorisedBy"
            v-model="authorisedBy"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.name"
              v-for="(item, index) in sourceData"
            />
            <!-- @input-change="handleSuburbSearch($event.target.value)" -->
          </vs-select>
        </div>

        <div class="w-full">
          <label for="notes" class="text-md"> Notes (Optional)</label>
          <vs-input
            name="notes"
            autocomplete
            placeholder="Add notes (Optional)"
            class="w-full"
            v-model="drugbookData.notes"
            data-vv-as="notes"
            data-vv-scope="drugbookData"
            :danger="errors.first('drugbookData.notes') ? true : false"
            :danger-text="errors.first('drugbookData.notes')"
            :success="!errors.first('drugbookData.notes') && drugbookData.notes != ''"
            val-icon-success="done"
            val-icon-danger="error"
          />
        </div>

        <div v-if="authorisedBy === 'other'" class="w-full">
          <label for="totalPrice" class="text-md"> Total Price <small>(Optional)</small> </label>
          <vs-input
            data-vv-as="totalPrice"
            name="totalPrice"
            placeholder="Total Price"
            v-model.number="totalPrice"
            class="w-full"
          />
        </div>

        <div class="w-full" v-if="authorisedBy === 'other'">
          <label for="currentCustomPrescriber" class="text-md"> Prescriber </label>
          <vs-select
            class="select"
            width="100%"
            name="currentCustomPrescriber"
            autocomplete
            v-model="currentCustomPrescriber"
            v-validate="'required'"
            data-vv-as="currentCustomPrescriber"
            :danger="errors.first('currentCustomPrescriber') ? true : false"
            :danger-text="errors.first('currentCustomPrescriber')"
            :success="!errors.first('currentCustomPrescriber') && currentCustomPrescriber != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :class="{'is-danger': errors.has('currentCustomPrescriber')}"

          >
            <div :key="index" v-for="(item, index) in customPrescribers">
              <vs-select-group :title="item.title" v-if="item.group">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in item.group"
                />
              </vs-select-group>
            </div>
            <vs-button
              class="absolute w-full right-0 py-2 rounded-t-none rounded-b-sm justify-start pl-3 z-10"
              :class="isAddCustomPrescriber ? 'hidden' : 'bottom-0'"
              color="#e1e8e6"
              text-color="#475c55"
              icon="add"
              @click="addCustomPrescriber"
            >
              Add Custom Prescriber
            </vs-button>
            <!-- <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.name"
              v-for="(item, index) in customPrescribers"
            /> -->
          </vs-select>
        </div>
        <!-- <span class="text-danger" style="font-size: 0.75em">
          {{ errors.first('currentCustomPrescriber') }}
        </span> -->

        <div v-if="currentCustomPrescriber === 'custom'" class="w-full">
          <vs-input
            name="customPrescriber"
            class="w-full"
            v-model="customPrescriber"
            v-validate="'required'"
            data-vv-as="customPrescriber"
            :danger="errors.first('customPrescriber') ? true : false"
            :danger-text="errors.first('customPrescriber')"
            :success="!errors.first('customPrescriber') && customPrescriber != ''"
            val-icon-success="done"
            val-icon-danger="error"
          />
        </div>
        <!-- <span class="text-danger" style="font-size: 0.75em">
          {{ errors.first('customPrescriber') }}
        </span> -->


        <div class="w-full">
          <label class="text-md">Batch Number</label>
          <vs-input
            name="batchNumber"
            class="w-full"
            v-model="drugbookData.batchNumber"
            v-validate="'required'"
            data-vv-as="batchNumber"
            data-vv-scope="drugbookData"
            :danger="errors.first('drugbookData.batchNumber') ? true : false"
            :danger-text="errors.first('drugbookData.batchNumber')"
            :success="!errors.first('drugbookData.batchNumber') && drugbookData.batchNumber != ''"
            val-icon-success="done"
            val-icon-danger="error"
          />
        </div>

        <div class="w-full">
          <label class="text-md">Batch Expiry</label>
          <datepicker
            format="dd MMMM yyyy"
            name="expiryDate"
            data-vv-as="expiryDate"
            v-model="drugbookData.expiryDate"
            v-validate="'required'"
            data-vv-scope="drugbookData"
            :danger="errors.first('drugbookData.expiryDate') ? true : false"
            :danger-text="errors.first('drugbookData.expiryDate')"
            :success="!errors.first('drugbookData.expiryDate') && drugbookData.expiryDate != ''"
            val-icon-success="done"
            val-icon-danger="error"
            class="input-text"
            :input-class="{
              'is-true': !errors.has('drugbookData.expiryDate') && drugbookData.expiryDate,
              'is-danger': errors.has('drugbookData.expiryDate'),
            }"
          >
            <!-- :input-class="{
              'is-true': !errors.has('drugbookData.entryDate') && drugbookData.entryDate,
              'is-danger': errors.has('drugbookData.entryDate'),
            }"
            <template slot="afterDateInput">
              <span
                v-if="drugbookData.expiryDate"
                class="
                  bg-color-done
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
              </span>
              <span
                v-if="errors.has('drugbookData.expiryDate')"
                class="
                  bg-color-error
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </template>
            -->
          </datepicker>
          <small class="text-danger">
            {{ errors.first('drugbookData.expiryDate') }}
          </small>
        </div>
      </div>
      <div class="flex items-center justify-between space-x-4 mt-5">
        <vs-button type="border" class="w-full flex-1 p-3" @click="onCancel">
          Cancel
        </vs-button>
        <vs-button type="border" class="bg-primary hover:bg-primary text-white w-full flex-1 p-3" @click="onSave" :disabled="isLoading">Save</vs-button>
      </div>
    </form>
  </vs-card>
</template>

<script>
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import { mapActions, mapMutations } from "vuex";
import { Validator } from "vee-validate";
import moment from "moment";
import jwt_decode from 'jwt-decode';

const dict = {
  custom: {
    amount: {
      required: "Please enter amount",
      min_value: "Please enter a valid amount",
    },
    entryDate: {
      required: "Please enter date",
    },
    productId: {
      required: "Please select drug",
    },
    timeAdded: {
      required: "Please enter time",
    },
    currentCustomPrescriber: {
      required: 'Prescriber is required'
    },
    notes: {
      required: "Notes is required"
    },
    batchNumber: {
      required: "Batch number is required"
    },
    expiryDate: {
      required: "Batch expiry is required"
    }
  },
};

Validator.localize("en", dict);
export default {
  props: [
    "closeAddRecord",
    "selectedClinic",
    "selectedDrug",
    "clinic",
    "isMobile",
    "show",
    "fetchCustomProductss",
  ],
  components: {
    Datepicker,
  },
  data: () => ({
    userId: "",
    discardTypes: [],
    batchNumbers: [],
    drugItems: [],
    drugDetail: {},
    organizationId: "",
    drugbookData: {
      clinicId: "",
      entryDate: moment().format("L"),
      amount: null,
      productId: null,
      batchNumber: "",
      timeAdded: moment().format("HH:mm"),
      expiryDate: "",
      notes:"",
    },
    authorisedBy: null,
    totalPrice: null,
    customPrescriber: "",
    sourceData: [
      {
        name: "Fresh Clinics",
        value: null,
      },
      {
        name: "Other",
        value: "other",
      },
    ],
    isLoading: false,
    isCustomProduct: false,
    customProductName: "",
    customProductUnit: "",
    filteredProducts: [],
    customProducts: [],
    customPrescribers: [],
    currentCustomPrescriber: "",
    isAddCustomPrescriber: false,
    isProductLoaded: false,
  }),
  methods: {
    ...mapActions("drugbook", [
      "addStockReceivedOne",
      "getAmountPerClinic",
      "fetchCustomProducts",
      "fetchCustomPrescribers",
    ]),
    ...mapActions("product", ["fetchProducts"]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    ...mapMutations("admin",["SET_BEARER"]),
    async getProductsList() {
      try {
        const res = await this.fetchProducts({
          limit: 1000,
        });
        this.drugItems = res.data.data.docs;
        if (this.drugItems.length > 0) {
          this.drugbookData.drug = this.selectedDrug;
          this.drugDetail = this.drugItems.filter(
            (e) => e._id === this.selectedDrug
          )[0];
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async getCustomProducts() {
      try {
        const res = await this.fetchCustomProducts(this.organizationId);
        this.customProducts = res.data.data
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
    async getCustomPrescribers() {
      try {
        const res = await this.fetchCustomPrescribers(this.organizationId);
        this.customPrescribers = [
          {
            title: "",
            group: [
              ...res.data.data.map((item) => {
                return {
                  text: item.name,
                  value: item._id,
                };
              }),
            ],
          },
          {
            title: "Add custom prescriber",
            group: [],
          },
        ];
      } catch (error) {
        console.error("PRESCRIBERS ERR: ", error);
      }
    },
    async fetchAmountPerClinic(clinicID, isClinicChange = true) {
      try {
        const res = await this.getAmountPerClinic({ clinicId: clinicID });
        const customIds = this.customProducts.map(item => item._id);
        const clinicsWithAmount = _.pullAll(res.data.data
        .map((e) => {
            const id = e._id.product || e._id.custom
            if(!customIds.includes(id)){
              return {
                  text: this.drugItems.find((item) => item._id === id).name,
                  value: id
                };
            }
            return undefined
          }),[undefined])
          .sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          );
        if (clinicsWithAmount && this.drugItems.length > 0) {
          let temp = this.drugItems
            .filter((e) => {
              const fromOrg = !e.organizationId && e.organizationId === this.organizationId;
              return !clinicsWithAmount.some((item) => item.value === e._id) && fromOrg;
            })
            .map((e) => {
              return {
                text: e.name,
                custom: e.organizationId,
                value: e._id,
              };
            });

          this.filteredProducts = [
            {
              title: "Stocked Products",
              group: [...clinicsWithAmount],
            },
            {
              title: "My Products",
              group: this.customProducts.map((value) => {
                        return {
                          text: value.name,
                          value: value._id,
                        };
                    }).sort((a, b) => a.text && b.text && a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1),
            },
            {
              title: "All Products",
              group: [...temp].sort((a, b) => a.text && b.text && a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1),
            },
            {
              title: "Add new product",
              group: [],
            },
          ];
          this.isProductLoaded = true;
          if (this.filteredProducts[1] && this.filteredProducts[1].group.length === 0) {
            this.filteredProducts.splice(1, 1);
          }

        }
      } catch (err) {
        console.error(err);
      }
    },
    onCancel() {
      this.closeAddRecord();
      this.clearForm();
      this.isCustomProduct = false;
      this.authorisedBy = null;
      this.customPrescriber = "";
      this.customProductName = "";
      this.customProductUnit = "";
      this.$validator.reset();
    },
    addCustomProduct() {
      let closeSelect = this.$refs.productSelect;
      closeSelect.active = false;
      this.isCustomProduct = true;
      const arrLength = this.filteredProducts.length-1;
      this.filteredProducts[arrLength].group = [...this.filteredProducts[arrLength].group,
        {
          value: "add",
          text: "+ Add new Product",
        },
      ]
      this.drugbookData.productId = "add";
    },
    addCustomPrescriber() {
      let closeSelect = this.$refs.customPrescriber;
      if(closeSelect) closeSelect.active = false;
      this.isAddCustomPrescriber = true;
      this.customPrescribers[1].title = "";
      this.customPrescribers[1].group = [
        ...this.customPrescribers[1].group,
        {
          value: "custom",
          text: "+ Add Custom Prescriber",
        },
      ];
      this.currentCustomPrescriber = "custom";
    },
    clearForm() {
      this.drugbookData.entryDate = moment().format("L");
      this.drugbookData.amount = null;
      this.drugbookData.batchNumber = "";
      this.drugbookData.timeAdded = moment().format("HH:mm");
      this.drugbookData.expiryDate = "";
      this.drugbookData.productId = this.selectedDrug;
    },
    async onSave() {
      let isValid = await this.$validator.validate();

      // this.drugbookData['performedBy'] = this.userId;

      let data = {
        clinicId: this.selectedClinic,
        entryDate: moment(this.drugbookData.entryDate)
          .hour(moment(this.drugbookData.timeAdded, "H:m").get("hour"))
          .minute(moment(this.drugbookData.timeAdded, "H:m").get("minute")),
        amount: this.drugbookData.amount.toFixed(2),
        performedBy: this.userId,
        batchNumber: this.drugbookData.batchNumber,
        ...(this.totalPrice && {otherPrescriberTotalAmount: this.totalPrice}),
      };

      if (this.drugbookData.notes) {
        data.notes = this.drugbookData.notes;
      }

      let isFreshProduct = this.filteredProducts[1].group.some(e => e.value === this.drugbookData.productId)
        if(this.filteredProducts.length === 4 && isFreshProduct){
          data = {
            ...data,
            customProductId: this.drugbookData.productId,
          }
        } else {
          data = {
            ...data,
            productId: this.drugbookData.productId,
          }
        }

      if (this.isCustomProduct) {
        data = {
          ...data,
          productId: "",
          newCustomProduct: {
            organizationId: this.organizationId,
            name: this.customProductName,
            unit: this.customProductUnit,
          },
        };
      }

      if (this.authorisedBy) {
        if (this.currentCustomPrescriber !== 'custom'){
          data = {
            ...data,
            otherPrescriberId: this.currentCustomPrescriber,
          };
        } else {
          data = {
            ...data,
            newCustomPrescriber: {
              organizationId: this.organizationId,
              name: this.customPrescriber,
            },
          };
        }
      }

      if (this.drugbookData.expiryDate !== "") {
        data["expiryDate"] = moment(this.drugbookData.expiryDate).format(
          "YYYY-MM-DD"
        );
      }

      if (isValid) {
        this.isLoading = true;
        try {
          const res = await this.addStockReceivedOne([data]);
          if (res.status === 200) {
            this.closeAddRecord();
            this.isCustomProduct = false;
            this.$vs.notify({
              title: "Added new drugbook record",
              text: "New drugbook record successfully created.",
              color: "success",
            });
            this.clearForm();
            const tempProduct = res.data.data[0].productId || res.data.data[0].customProductId;
            this.$emit("changeSelectedDrug", tempProduct);
            this.$emit("fetchDrugEntries");
            this.$emit("fetchCustomProductss");
            this.$validator.reset();
          } else {
            this.$vs.notify({
              title: "Failed",
              text: "Failed to add new Drugbook record. Try again!",
              color: "danger",
            });
          }
          this.isLoading = false;
        } catch (e) {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to add new Drugbook record. Try again!",
            color: "danger",
          });
          this.isLoading = false;
        }
      }
    },
    async fetchOrgIdByClinic(){
      const res = await this.fetchClinicsDetail(this.$route.query.clinicId)
          const clinicsDetail = res.data.data
          const orgId = clinicsDetail.organizationDetail._id
          return orgId;
    },
  },
  watch: {
    drugbookData: {
      handler(val) {
        if (val.productId !== "add") {
          this.isCustomProduct = false;
          this.customProductName = "";
          this.customProductUnit = "";
          const arrLength = this.filteredProducts.length-1;
          if (this.filteredProducts.length &&
            this.filteredProducts[arrLength] &&
            this.filteredProducts[arrLength].group.length &&
            this.filteredProducts[arrLength].group[this.filteredProducts[arrLength].group.length - 1].value === 'add'
          ){
            this.filteredProducts[arrLength].group.pop();
          }
        }
      },
      deep: true,
    },
    "drugbookData.productId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drugDetail = this.drugItems.filter((e) => e._id === newVal)[0];
      }
    },
    selectedDrug:  {
      handler() {
        if (this.drugItems.length > 0) {
          this.drugbookData.drug = this.selectedDrug;
          this.drugDetail = this.drugItems.filter(
            (e) => e._id === this.selectedDrug
          )[0];
        }
      }
    },
    '$route.query.selectedClinic': function (newVal, oldVal) {
      if (newVal) this.fetchAmountPerClinic(newVal);
    },
    show: function (newVal, oldVal) {
      this.drugbookData.timeAdded = moment().format("HH:mm");
    },
    currentCustomPrescriber: {
      handler(val) {
        if (val !== "custom") {
          this.isAddCustomPrescriber = false;
          if (
            this.customPrescribers.length &&
            this.customPrescribers[1].group[
              this.customPrescribers[1].group.length - 1
            ].value === "custom"
          ) {
            this.customPrescribers[1].title = "Add Custom Prescriber";
            this.customPrescribers[1].group.pop();
          }
        }
      },
    },
  },
  computed: {
    formattedAmount: {
      get: function () {
        const d = Math.pow(10, 2);
        return (
          this.drugbookData.amount &&
          Math.round((this.drugbookData.amount + Number.EPSILON) * d) / d
        );
      },
      set: function (newValue) {
        this.drugbookData.amount = newValue;
      },
    },
    isNZRegion() {
      return process.env.VUE_APP_REGION === "NZ"
    }
  },
  async created() {
    this.token = this.$route.query.token
    this.embedClinicId = this.$route.query.clinicId
    this.SET_BEARER(this.$route.query.token)
    if(this.$route.query.token && this.$route.query.token.length>0){
      this.userId = jwt_decode(this.$route.query.token).id;
    }
    else{
      this.userId = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      )._id;
    }
    if(this.$route.query.clinicId && this.$route.query.clinicId.length>0){
      this.organizationId = await this.fetchOrgIdByClinic()
      await this.getProductsList();
      await this.getCustomProducts();
      this.drugbookData.productId = this.selectedDrug;
    }
    else if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      this.getProductsList();
      this.drugbookData.productId = this.selectedDrug;
      this.getCustomProducts();
    }
  },
  mounted() {
    setTimeout(() => {
      let clinic = this.selectedClinic ? this.selectedClinic : this.$route.query.selectedClinic;
      if (clinic) this.fetchAmountPerClinic(clinic);
      this.getCustomPrescribers();
    }, 1000);
  },
};
</script>

<style lang="scss">
  .vdp-datepicker__calendar {
    z-index: 99999 !important;
  }

  .popUpCustom {
    .con-vs-card {
      border: none !important;
    }
    .vs-card--content {
      max-height: 75vh;
      overflow: auto;
    }
  }

  .drugbookFields {
    .vs-input--input,
    .vs-input--placeholder,
    .vs-select--input,
    .vdp-datepicker input,
    input {
      font-family: inherit !important;
      font-size: 16px !important;
      padding: 0.8rem 0.8rem;
    }
  }

  .input-text .is-danger,
  .is-danger input {
    border: 1px solid rgba(var(--vs-danger),1)!important
  }
  .bg-color-error{
    background: rgba(var(--vs-danger), .2);
    color: rgba(var(--vs-danger), 1);
  }
</style>

<style scoped>
.customButton{
  bottom: -1.7vmax;
}
</style>
